import React, { useContext, useState, useEffect } from 'react';
type defaultState = {
  shortlistEnabled: boolean;
  shortlistedPropertySlugs: string[];
};
const defaultExperimentState: defaultState = {
  shortlistEnabled: false,
  shortlistedPropertySlugs: []
};
const ExperimentContext = React.createContext(defaultExperimentState);
export const ExperimentProvider = ({
  children,
  initialState
}: {
  children: React.ReactNode;
  initialState?: defaultState;
}) => {
  const [state, setState] = useState(initialState || defaultExperimentState);
  useEffect(() => {
    const updateStateFromLocalStorage = (e?: StorageEvent) => {
      const shortlistEnabled = window.localStorage.getItem('openafs001') === 'true';
      const shortlistedProperties = window.localStorage.getItem('wishlist');
      const shortlistedPropertySlugs = shortlistedProperties ? JSON.parse(shortlistedProperties).map((p: {
        propertyLastPartURL: string;
      }) => p.propertyLastPartURL) : [];
      if (!e) {
        setState({
          shortlistEnabled,
          shortlistedPropertySlugs
        });
      }
      if (e && e.key !== 'wishlist' && e.key !== 'openafs001') return;
      if (e?.key === 'openafs001') {
        setState(prevState => ({
          ...prevState,
          shortlistEnabled
        }));
      }
      if (e?.key === 'wishlist') {
        setState(prevState => ({
          ...prevState,
          shortlistedPropertySlugs
        }));
      }
    };
    updateStateFromLocalStorage();
    window.addEventListener('storage', updateStateFromLocalStorage);
    return () => {
      window.removeEventListener('storage', updateStateFromLocalStorage);
    };
  }, []);
  return <ExperimentContext.Provider value={{
    ...state
  }} data-sentry-element="unknown" data-sentry-component="ExperimentProvider" data-sentry-source-file="experimentContext.tsx">
      {children}
    </ExperimentContext.Provider>;
};
export const useExperimentContext = () => useContext(ExperimentContext);