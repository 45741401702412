/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { StateMachineProvider as FormProvider, createStore } from 'little-state-machine';
import { OverlayProvider } from '@afs/components/OverlayContext';
import useTabbingFocus from '../hooks/useTabbingFocusListener';
import canUseDOM from '../utils/canUseDom';
import config from '../config';
import { CountryProvider, useCountryContext, countryStateFromUrl } from '../contexts/countryContext';
import { AutocompleteProvider } from '../contexts/autocompleteContext';
import { ExperimentProvider } from '../contexts/experimentContext';
import useMedia from '../hooks/useMedia';
import { defaultBookingState } from '../state/booking';
import { defaultIqBookingState } from '../state/iqBooking';
import '@afs/styles/main-without-fonts.scss';
import '@afs/components/styles/website.min.css';
if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  const ReactDOM = require('react-dom');
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}
createStore({
  booking: defaultBookingState,
  iqBooking: defaultIqBookingState
});
function App({
  Component,
  pageProps
}: AppProps) {
  const {
    asPath,
    events
  } = useRouter();
  const {
    update: updateCountryContext
  } = useCountryContext() as any;
  const onRouteChangeStart = (nextPath: string) => {
    const nextCountryState = countryStateFromUrl(nextPath);
    updateCountryContext(nextCountryState);
  };
  useEffect(() => {
    events.on('routeChangeStart', onRouteChangeStart);
    return () => {
      events.off('routeChangeStart', onRouteChangeStart);
    };
  }, [events]);
  if (canUseDOM) {
    useTabbingFocus(window);
  }
  const isDesktop = useMedia(['(min-width: 769px)'], [true], false);
  const shouldRenderIntercomOnThisRoute = !asPath.includes('/property') && !asPath.includes('/student-hall') && !asPath.includes('/book-room');
  const {
    boot,
    shutdown,
    hide,
    update
  } = useIntercom();
  useEffect(() => {
    const isDesktopRouteWithIntercom = isDesktop && shouldRenderIntercomOnThisRoute;
    if (isDesktopRouteWithIntercom) {
      boot();
      update({
        hideDefaultLauncher: false
      });
    } else {
      hide();
      update({
        hideDefaultLauncher: true
      });
      shutdown();
    }
  }, [isDesktop, shouldRenderIntercomOnThisRoute]);
  const shouldRenderWebsiteSchemaOnThisRoute = asPath !== '/' && asPath !== '/ie' && asPath !== '/au';
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <meta charSet="utf-8" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0 interactive-widget=resizes-content" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="theme-color" content="#ec6b1e" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="color-scheme" content="only light" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <base href="/" />
        <link rel="preload" href="/fonts/apax-regular.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preconnect" href="https://www.google-analytics.com" crossOrigin="anonymous" />
        <link rel="preconnect" href="https://www.googletagmanager.com" crossOrigin="anonymous" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/media/favicons/favicon-152x152.png" />
        <link rel="icon" type="image/png" href="/media/favicons/favicon-16x16.png" sizes="16x16" />
        <link rel="icon" type="image/png" href="/media/favicons/favicon-32x32.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="/media/favicons/favicon-48x48.png" sizes="48x48" />
        <link rel="icon" type="image/png" href="/media/favicons/favicon-96x96.png" sizes="96x96" />
        <link rel="icon" type="image/png" href="/media/favicons/favicon-128x128.png" sizes="128x128" />
        <link rel="icon" type="image/png" href="/media/favicons/favicon-152x152.png" sizes="152x152" />
        <link rel="icon" type="image/png" href="/media/favicons/favicon-192x192.png" sizes="192x192" />
        <link rel="icon" type="image/png" href="/media/favicons/favicon-512x512.png" sizes="512x512" />
        <link rel="manifest" href="/manifest.json" />
        <style dangerouslySetInnerHTML={{
        __html: `
                @font-face {
                  font-family: 'ApaxSubset';
                  src: url('/fonts/apax-regular.woff2') format('woff2'),
                      url('/fonts/apax-regular.woff') format('woff');
                  font-display: block;
                }

                @font-face {
                  font-family: 'Apax';
                  src: url('/fonts/apax-regular.woff2') format('woff2'),
                      url('/fonts/apax-regular.woff') format('woff');
                  font-display: swap;
                }

                @font-face {
                  font-family: 'Apax';
                  src: url('/fonts/apax-regular-italic.woff2') format('woff2'),
                      url('/fonts/apax-regular-italic.woff') format('woff');
                  font-style: italic;
                  font-display: swap;
                }

                @font-face {
                  font-family: 'Apax';
                  src: url('/fonts/apax-medium.woff2') format('woff2'),
                      url('/fonts/apax-medium.woff') format('woff');
                  font-weight: 500;
                  font-display: swap;
                }

                @font-face {
                  font-family: 'Apax';
                  src: url('/fonts/apax-bold.woff2') format('woff2'),
                      url('/fonts/apax-bold.woff') format('woff');
                  font-weight: 700;
                  font-display: swap;
                }

                body {
                  font-family: Helvetica, Arial, sans-serif;
                }

                .fonts-loaded-1 body {
                  font-family: 'ApaxSubset', Helvetica, Arial, sans-serif;
                }

                .fonts-loaded-2 body {
                  font-family: 'Apax', 'ApaxSubset', Helvetica, Arial, sans-serif;
                }
              `
      }} />
      </Head>

      {config.runtimeEnvironment !== 'local' && <>
          <Script strategy="beforeInteractive" dangerouslySetInnerHTML={{
        __html: `
            var _conv_prevent_bodyhide = true;
            `
      }} />

          <Script strategy="beforeInteractive" async src="//cdn-4.convertexperiments.com/v1/js/10041665-100412621.js" />
        </>}

      <Script
    // Fonts preload script
    strategy="beforeInteractive" dangerouslySetInnerHTML={{
      __html: `
                (function() {
                  if ('fonts' in document) {
                    // Optimization for Repeat Views
                    if (sessionStorage.fontsLoadedCriticalFoftPreload) {
                      document.documentElement.classList.add('fonts-loaded-2');
                      return;
                    }

                    document.fonts.load('1em ApaxSubset').then(function () {
                      document.documentElement.classList.add('fonts-loaded-1');

                      Promise.all([
                        document.fonts.load('400 1em Apax'),
                        document.fonts.load('italic 400 1em Apax'),
                        document.fonts.load('500 1em Apax'),
                        document.fonts.load('700 1em Apax'),
                      ]).then(function() {
                        document.documentElement.classList.add('fonts-loaded-2');

                        // Optimization for Repeat Views
                        sessionStorage.fontsLoadedCriticalFoftPreload = true;
                      });
                    });
                  }
                })();
              `
    }} data-sentry-element="Script" data-sentry-source-file="_app.tsx" />

      <Script strategy="beforeInteractive" type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
                {
                  "@context" : "https://schema.org",
                  "@type" : "Organization",
                  "name" : "Accommodation for Students",
                  "url" : "https://www.accommodationforstudents.com",
                  "sameAs" : [
                  "https://www.trustpilot.com/review/www.accommodationforstudents.com",
                  "https://www.pinterest.com/afsuk/",
                  "https://www.facebook.com/accommodation4students",
                  "https://twitter.com/accomforstudent",
                  "https://www.linkedin.com/company/accommodation-for-students-limited",
                  "https://www.tiktok.com/@accommodationforstudents"
                  ],
                  "aggregateRating": {
                    "@type" :"AggregateRating",
                    "ratingValue":"9",
                    "bestRating":"10",
                    "reviewCount":"73"
                  }
                }
            `
    }} data-sentry-element="Script" data-sentry-source-file="_app.tsx" />

      {shouldRenderWebsiteSchemaOnThisRoute ? <Script strategy="beforeInteractive" type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
                {
                  "@context" : "https://schema.org",
                  "@type" : "WebSite",
                  "additionalType": "Accommodation",
                  "name" : "Accommodation for Students",
                  "url" : "https://www.accommodationforstudents.com"
                }
            `
    }} /> : null}

      <Script
    // Modernizr script for lazy loading images
    strategy="beforeInteractive" src="/scripts/modernizr.js" data-sentry-element="Script" data-sentry-source-file="_app.tsx" />

      {!config.isLocalEnvironment ? <Script
    // Google Tag Manager script - does not load in local environment to prevent Cookie Banner from displaying
    strategy="lazyOnload" dangerouslySetInnerHTML={{
      __html: `
                (function(w, d, s, l, i) {
                  w[l] = w[l] || [];
                  w[l].push({
                  'gtm.start':
                  new Date().getTime(),
                  event: 'gtm.js'
                });
                  var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s),
                  dl = l != 'dataLayer' ? '&l=' + l : '';
                  j.async = true;
                  j.src =
                  'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                  f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', 'GTM-MRTQC53');
              `
    }} /> : null}

      <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
    </>;
}
export default function AppWrapper(props: AppProps) {
  return <FormProvider data-sentry-element="FormProvider" data-sentry-component="AppWrapper" data-sentry-source-file="_app.tsx">
      <IntercomProvider appId={config.intercomID} data-sentry-element="IntercomProvider" data-sentry-source-file="_app.tsx">
        <CountryProvider initialState={props.pageProps.countryState} data-sentry-element="CountryProvider" data-sentry-source-file="_app.tsx">
          <ExperimentProvider data-sentry-element="ExperimentProvider" data-sentry-source-file="_app.tsx">
            <OverlayProvider data-sentry-element="OverlayProvider" data-sentry-source-file="_app.tsx">
              <AutocompleteProvider data-sentry-element="AutocompleteProvider" data-sentry-source-file="_app.tsx">
                <App {...props} data-sentry-element="App" data-sentry-source-file="_app.tsx" />
              </AutocompleteProvider>
            </OverlayProvider>
          </ExperimentProvider>
        </CountryProvider>
      </IntercomProvider>
    </FormProvider>;
}